<template>
  <b-modal
    ref="modalCreatePathway"
    :visible="createPathwayActive"
    @hidden="closeModal"
    size="lg"
    :busy="busy"
    :title="`New Pathway`"
    ok-title="Create Pathway"
    centered
    body-class="pt-0"
    @ok="createEntity"
  >
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-form-group label="Pathway Name" label-for="pathway-name">
          <b-form-input required autofocus id="pathway-name" placeholder="" v-model="pathwayCreateData.name" />
        </b-form-group>
      </b-col>
      <!-- <b-col cols="12" md="12" lg="12">
        <b-form-group label="Pathway Avatar URL" label-for="pathway-avatar">
          <b-form-input required id="pathway-avatar" placeholder="" v-model="pathwayCreateData.avatar" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="8" lg="8">
        <b-form-group label="Pathway Background URL" label-for="pathway-avatar">
          <b-form-input required id="pathway-avatar" placeholder="" v-model="pathwayCreateData.metadata.design.image" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-form-group label="Pathway Color HEX" label-for="pathway-color">
          <b-form-input required id="pathway-color" placeholder="" v-model="pathwayCreateData.metadata.design.color" />
        </b-form-group>
      </b-col> -->
      <b-col cols="12" md="4" lg="4">
        <b-form-group label="Visibility" label-for="pathway-visibility">
          <v-select
            v-model="pathwayCreateData.visibility"
            :options="visibilityOptions"
            :reduce="(val) => val.value"
            :clearable="false"
            required
            transition="smooth"
            input-id="pathway-visibility"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-form-group label="Status" label-for="pathway-status">
          <v-select
            v-model="pathwayCreateData.status"
            :options="statusOptions"
            :reduce="(val) => val.value"
            :clearable="false"
            required
            transition="smooth"
            input-id="pathway-status"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BCol, BFormGroup, BButton, BFormInput, BFormTextarea, BFormSelect, BFormSelectOption, BModal, BRow, VBModal } from 'bootstrap-vue';

import 'quill/dist/quill.snow.css';
import store from '@/store';
import placementStoreModule from '@/views/apps/placement/placementStoreModule';
import { ref, onUnmounted } from '@vue/composition-api/dist/vue-composition-api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import usePathways from '../usePathways';
import vSelect from 'vue-select';
import _ from 'lodash';

export default {
  name: 'PathwayCreateModal',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BModal,
    vSelect,
  },
  directives: { 'b-modal': VBModal },
  props: {
    createPathwayActive: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  watch: {
    '$props.createPathwayActive'(val) {
      this.initModal();
    },
  },
  created() {
    this.initModal();
  },
  methods: {
    initModal() {
      this.pathwayCreateData = {
        name: null,
        avatar: null,
        visibility: 'public',
        status: 'created',
        metadata: {
          design: {
            color: null,
            image: null,
          },
        },
      };
    },
    closeModal() {
      this.busy = false;
      this.$emit('update:create-pathway-active', false);
    },

    createEntity(event) {
      this.busy = true;

      event.preventDefault();

      const pl = { ...this.pathwayCreateData };

      if (pl.metadata.design.image === '') {
        pl.metadata.design.image = null;
      }

      if (pl.metadata.design.color === '') {
        pl.metadata.design.color = null;
      }

      store
        .dispatch('rosterStoreModule/createPathway', pl)
        .then((response) => {
          this.busy = false;
          this.$emit('refetch-data');
          this.closeModal();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Pathway Created!',
              icon: 'CreateIcon',
              variant: 'success',
            },
          });

          this.$router.push('/roster/pathways/' + response.data.id);
        })
        .catch((error) => {
          this.busy = false;
          this.$emit('refetch-data');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create pathway',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  setup(props) {
    const pathwayCreateData = ref({});
    const busy = ref(false);

    const { statusOptions, visibilityOptions } = usePathways();

    return { statusOptions, visibilityOptions, pathwayCreateData, busy };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
