<template>
  <b-modal
    ref="modalUpsertTemplate"
    :visible="editPathwayActive"
    @hidden="closeModal"
    size="lg"
    :busy="busy"
    :title="`Edit Pathway`"
    ok-title="Save Changes"
    centered
    body-class="pt-0"
    @ok="saveChanges"
  >
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-form-group label="Pathway Name" label-for="pathway-name">
          <b-form-input required autofocus id="pathway-name" placeholder="" v-model="pathwayEditData.name" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="12" lg="12">
        <b-form-group label="Pathway Avatar URL" label-for="pathway-avatar">
          <b-form-input required autofocus id="pathway-avatar" v-model="pathwayEditData.avatar" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="8" lg="8">
        <b-form-group label="Pathway Background URL" label-for="pathway-avatar">
          <b-form-input required autofocus id="pathway-avatar" v-model="pathwayEditData.metadata.design.image" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-form-group label="Pathway Color HEX" label-for="pathway-color">
          <b-form-input
            autofocus
            id="pathway-color"
            v-model="pathwayEditData.metadata.design.color"
            @input="handleColorInput"
            :state="isColorValid === false ? false : null"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-form-group label="pathway Hours" label-for="pathway-hours">
          <b-form-input required autofocus id="pathway-hours" v-model="pathwayEditData.hours" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-form-group label="pathway Cost" label-for="pathway-cost">
          <b-form-input required autofocus id="pathway-cost" v-model="pathwayEditData.cost" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-form-group label="Visibility" label-for="pathway-visibility">
          <v-select
            v-model="pathwayEditData.visibility"
            :options="visibilityOptions"
            :reduce="(val) => val.value"
            :clearable="false"
            required
            transition="smooth"
            input-id="pathway-visibility"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-form-group label="Status" label-for="pathway-status">
          <v-select
            v-model="pathwayEditData.status"
            :options="statusOptions"
            :reduce="(val) => val.value"
            :clearable="false"
            required
            transition="smooth"
            input-id="pathway-status"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BCol, BFormGroup, BButton, BFormInput, BFormTextarea, BFormSelect, BFormSelectOption, BModal, BRow, VBModal } from 'bootstrap-vue';

import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import { validateHexColor } from '@/libs/utils/color';
import { ref, onUnmounted } from '@vue/composition-api/dist/vue-composition-api';
import store from '@/store';
import placementStoreModule from '@/views/apps/placement/placementStoreModule';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import usePathways from '../usePathways';
import vSelect from 'vue-select';
import _ from 'lodash';

export default {
  name: 'PathwayEditModal',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BModal,
    quillEditor,
    vSelect,
  },
  directives: { 'b-modal': VBModal },
  props: {
    editPathwayActive: {
      type: Boolean,
      required: true,
      default: false,
    },
    pathwayData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isColorValid: null,
    };
  },
  watch: {
    '$props.editPathwayActive'(val) {
      this.initModal();
    },
    '$props.pathwayData'(val) {
      this.initModal();
    },
  },
  created() {
    this.initModal();
  },
  methods: {
    initModal() {
      if (this.$props.pathwayData) {
        const { name, avatar, visibility, id, cost, hours, status, metadata } = _.cloneDeep(this.$props.pathwayData);
        this.pathwayEditData = {
          name,
          avatar,
          visibility,
          id,
          cost,
          hours,
          status,
          metadata: {
            design: {
              color: '',
              image: '',
            },
            ...metadata,
          },
        };
      }
    },
    closeModal() {
      this.busy = false;
      this.$emit('update:edit-pathway-active', false);
    },
    handleColorInput() {
      const color = this.pathwayEditData.metadata.design.color;
      this.isColorValid = validateHexColor(color);
    },
    saveChanges(event) {
      this.busy = true;

      event.preventDefault();

      this.handleColorInput();
      if (!this.isColorValid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invalid HEX Color',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Please enter a valid hex color code',
          },
        });
        this.busy = false;
        return;
      }

      const pl = { ...this.pathwayEditData };

      if (pl.metadata.design.image === '') {
        pl.metadata.design.image = null;
      }

      if (pl.metadata.design.color === '') {
        pl.metadata.design.color = null;
      }

      store
        .dispatch('rosterStoreModule/updatePathway', { pathwayId: pl.id, pathwayData: pl })
        .then((response) => {
          this.busy = false;
          this.$emit('refetch-data');
          this.closeModal();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Pathway Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.busy = false;
          this.$emit('refetch-data');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit pathway',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  setup(props) {
    const pathwayEditData = ref({});
    const busy = ref(false);

    // Register module

    const { statusOptions, visibilityOptions } = usePathways();

    return { statusOptions, visibilityOptions, pathwayEditData, busy };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
