<template>
  <div class="pt-1">
    <pathway-create-modal
      v-if="$can('roster.pathway.create')"
      :create-pathway-active.sync="createPathwayActive"
      @refetch-data="refetchData"
    />

    <pathway-edit-modal
      v-if="$can('roster.pathway.update')"
      :edit-pathway-active.sync="editPathwayActive"
      :pathway-data.sync="editPathwayData"
      @refetch-data="refetchData"
    />

    <div class="content-px mb-1">
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="d-flex align-items-center justify-content-start flex-wrap flex-sm-nowrap" style="gap: 0.675rem">
              <b-form-input v-model="searchQuery" class="drop-shadow-default" placeholder="Search..." style="max-width: 28rem" />
              <pathway-list-filters
                :visibility-filter.sync="visibilityFilter"
                :status-filter.sync="statusFilter"
                :visibility-options="visibilityOptions"
                :status-options="statusOptions"
              />
            </div>

            <b-button
              v-if="$can('roster.pathway.create')"
              variant="primary"
              @click="createPathwayActive = true"
              class="ml-1"
              style="line-height: 1rem; font-size: 1rem"
            >
              <feather-icon icon="PlusCircleIcon" size="16" class="mr-25" />
              <span class="text-nowrap">New Pathway</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <div no-body class="mb-0 table-no-top">
      <b-table
        ref="refPathwayListTable"
        class="position-relative mb-0"
        responsive
        :items="fetchPathwayList"
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @row-contextmenu="onRowContextMenu"
      >
        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Age:</b></b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Is Active:</b></b-col>
            </b-row>
          </b-card>
        </template>

        <!-- Column: Pathway -->
        <template #cell(name)="data">
          <div class="d-flex align-items-center" style="gap: 0.625rem">
            <b-avatar
              size="36"
              :src="data.item.avatar"
              :text="avatarText(data.item.name)"
              :variant="`light-info`"
              :to="{ name: 'apps-roster-pathway-view', params: { id: data.item.id } }"
              rounded
            />
            <div class="d-flex flex-column" style="gap: 0">
              <b-link
                class="font-weight-bold text-nowrap text-white mb-0"
                :to="{ name: 'apps-roster-pathway-view', params: { id: data.item.id } }"
                style="font-size: 1.25rem; line-height: 1.75rem"
              >
                {{ data.item.name }}
              </b-link>
              <small class="text-muted" style="font-size: 1.05rem; line-height: 1.35rem"
                >{{ (data.item.sessions || []).length }} Cohorts</small
              >
            </div>
          </div>
        </template>

        <!-- Column: Courses -->
        <template #cell(courses)="data">
          <div class="d-flex align-items-center" style="gap: 0.625rem">
            <div v-for="course in data.value" v-if="course" :key="course.id">
              <div class="d-flex flex-column" :id="`pathway-course-${course.id}${data.item.id}`">
                <b-avatar
                  size="36"
                  :src="course.avatar"
                  :text="avatarText(course.name)"
                  :variant="`light-info`"
                  :to="{ name: 'apps-roster-course-view', params: { id: course.id } }"
                  class="align-self-center"
                  rounded
                />
              </div>
              <b-tooltip :title="course.name" class="cursor-pointer" :target="`pathway-course-${course.id}${data.item.id}`" />
            </div>
          </div>
        </template>

        <!-- Column: Live Sessions -->
        <template #cell(live_cohorts)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize" :class="data.value ? 'text-success' : 'text-muted'">{{
              data.value ? `${data.value} Cohort${data.value > 1 ? 's' : ''}` : 'None'
            }}</span>
          </div>
        </template>
        <!-- Column: Complete Sessions -->
        <template #cell(completed_cohorts)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize" :class="data.value ? 'text-white' : 'text-muted'">{{
              data.value ? `${data.value} Cohort${data.value > 1 ? 's' : ''}` : 'None'
            }}</span>
          </div>
        </template>

        <!-- Column: Visibility -->
        <template #cell(visibility)="data">
          <b-badge :variant="`light-${resolvePathwayVisibilityVariant(data.item.visibility)}`" class="text-capitalize">
            {{ data.item.visibility.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge :variant="`${resolvePathwayStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status.split('_').join(' ') }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="primary" class="dropdown-edit-button">
            <template #button-content>
              <div class="p-50">
                <feather-icon icon="MoreHorizontalIcon" size="21" />
              </div>
            </template>

            <b-dropdown-item v-if="$can('roster.pathway.update')" @click="editPathway(data.item)">
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>Edit</span>
                <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('roster.pathway.get')"
              @click="$router.push({ name: 'apps-roster-pathway-view', params: { id: data.item.id } })"
            >
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>View</span>
                <feather-icon icon="EyeIcon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-50">
        <b-row>
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="mb-0 text-muted">{{ dataMeta.of }} Total Entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
            <div class="mr-2 vue-select-up">
              <span class="mb-0 mr-75">Rows Per Page</span>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block"
                :open="true"
              />
            </div>
            <span class="mr-2 ml-lg-50 mb-0">Page {{ currentPage }} of {{ totalPageCount }}</span>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPathways"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-sm-0 ml-lg-50"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>

    <vue-context ref="pathwayListMenu" class="mw-8" v-slot="{ data }">
      <li v-if="data">
        <a v-if="$can('roster.pathway.update')" class="d-flex align-items-center justify-content-between" @click="editPathway(data.item)">
          <span>Edit</span>
          <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
        </a>
        <a
          v-if="$can('roster.pathway.get')"
          class="d-flex align-items-center justify-content-between"
          @click="$router.push({ name: 'apps-roster-pathway-view', params: { id: data.item.id } })"
        >
          <span>View</span>
          <feather-icon icon="EyeIcon" size="15" class="ml-50 text-muted" />
        </a>
      </li>
    </vue-context>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import PathwayListFilters from './PathwayListFilters.vue';
import usePathways from '../usePathways';
import PathwayEditModal from '../edit/PathwayEditModal.vue';
import PathwayCreateModal from '../create/PathwayCreateModal.vue';
import VueContext from 'vue-context';

export default {
  components: {
    PathwayListFilters,
    PathwayEditModal,
    PathwayCreateModal,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    VueContext,
    vSelect,
  },
  methods: {
    newPathway() {
      this.createPathwayActive = true;
    },
    editPathway(data) {
      this.editPathwayData = data;
      this.editPathwayActive = true;
    },
    onRowContextMenu(item, index, event) {
      this.$refs.pathwayListMenu.open(event, { item });
      event.preventDefault();
    },
  },
  mounted() {
    this.$root.$on('fireRootEventN', this.newPathway);
  },
  beforeDestroy() {
    this.$root.$off('fireRootEventN', this.newPathway);
  },
  setup() {
    const editPathwayData = ref({});
    const editPathwayActive = ref(false);
    const createPathwayActive = ref(false);

    const {
      fetchPathwayList,
      tableColumns,
      perPage,
      currentPage,
      totalPathways,
      totalPageCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPathwayListTable,
      refetchData,

      // UI
      resolvePathwayRoleVariant,
      resolvePathwayRoleIcon,
      resolvePathwayVisibilityVariant,
      resolvePathwayStatusVariant,

      // Extra Filters
      visibilityOptions,
      statusOptions,
      visibilityFilter,
      statusFilter,

      // Loaded
      isLoaded,
    } = usePathways();

    return {
      fetchPathwayList,
      tableColumns,
      perPage,
      currentPage,
      totalPathways,
      totalPageCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPathwayListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolvePathwayRoleVariant,
      resolvePathwayRoleIcon,
      resolvePathwayVisibilityVariant,
      resolvePathwayStatusVariant,

      visibilityOptions,
      statusOptions,

      // Extra Filters
      visibilityFilter,
      statusFilter,

      // Loaded
      isLoaded,

      editPathwayData,
      editPathwayActive,
      createPathwayActive,
    };
  },
  metaInfo() {
    return {
      title: 'Pathways',
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
